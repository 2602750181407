import React from "react";
import picture from "../../images/AdobeStock_377803399.jpeg";
const WeUnlock = () => {
  return (
    <div className="w-full">
      <div className="flex flex-col md:flex-row">
        <div className=" md:max-w-3xl p-10 md:p-16">
          <h1 className="text-black text-xl title pb-6">
            We Unlock the Hidden Value in Your Marketing Data{" "}
          </h1>
          <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
          <p className="text-primary unlock-title  pb-4">
            With a decade of experience in enterprise-level political advocacy
            and marketing analytics, Astra Intel understands the industry's
            unique challenges your team faces. Our data engineering and
            analytics experts bring you the tools and insights you need to drive
            meaningful results. We specialize in transforming complex data into
            actionable strategies, empowering your campaigns to exceed
            expectations.
          </p>
          {/* <p className="text-primary unlock-title  pb-4">
            In 2015 &nbsp;
            <a
              className="text-blue-500 title text-2xl pb-4"
              href="https://www.mckinsey.com/about-us/new-at-mckinsey-blog/accelerating-with-quantumblack"
            >
              we became part of McKinsey & Company.&nbsp;
            </a>
            Together we combine our advanced analytics, design and engineering
            expertise with McKinsey’s industry knowledge and organisational
            change management experience.
          </p> */}
        </div>
        <div className="px-6 md:px-0">
          <img style={{ height: "500px", width: "750px", objectFit: "cover" }} src={picture} alt="picture"  />
        </div>
      </div>
    </div>
  );
};

export default WeUnlock;
