import React from "react";

const Header = () => {
  return (
    <div className="header">
      <div className="max-w-7xl mx-auto px-10 md:px-20">
        <h1 className="title text-white text-5xl md:text-6xl  pt-48 pb-4">
          Data Is Your{" "}
        </h1>
        <h1 className="title text-white text-5xl md:text-6xl  pb-8">
          {" "}
          Campaign Advantage
        </h1>
        <p className=" h-1 bg-blue-500 w-24 mb-14"></p>
        <p className="title text-xl text-white max-w-md">
          Leverage Your Data with Full-Service Data Management & AI Consulting
        </p>
      </div>
    </div>
  );
};

export default Header;
