import React from "react";
import picture1 from "../../images/clarity.svg";
import picture2 from "../../images/insight.svg";
import picture3 from "../../images/machine.svg";
const Specs = () => {
  return (
    <div className="w-full bg-lightGray">
      <div className="max-w-7xl mx-auto px-10 md:px-16 py-16">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <img src={picture1} alt="picture" className="w-20 mb-5" />
            <h1 className="text-black font-semibold text-xl title pb-4">
              Clarity of Purpose
            </h1>
            <p className="text-primary title  text-xl  pb-4">
              Every engagement is focused on determining what will have the
              biggest impact on an organisation.
            </p>
          </div>

          <div>
            <img src={picture2} alt="picture" className="w-20 mb-5" />
            <h1 className="text-black font-semibold text-xl title pb-4">
              Insights to Capability
            </h1>
            <p className="text-primary title  text-xl  pb-4">
              We uncover robust new insights and then help our clients build
              capabilities to achieve continuous improvements.
            </p>
          </div>

          <div>
            <img src={picture3} alt="picture" className="w-20 mb-5" />
            <h1 className="text-black font-semibold text-xl title pb-4">
              Human + Machine
            </h1>
            <p className="text-primary title  text-xl  pb-4">
              What we can measure and analyse, we can understand and change. We
              strive for clear analysis and insights from our cutting-edge
              technology.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specs;
