import React from "react";
import picture from "../../images/AdobeStock_181346565.jpeg";
const BestPeople = () => {
  return (
    <div className="w-full bg-lightGray">
      <div className="flex flex-col md:flex-row">
        <div className=" md:max-w-3xl px-10 md:p-16 mb-14 md:mb-4">
          <h1 className="text-black text-xl title pb-6">
            Data Fuels the AI Revolution
          </h1>
          <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
          <p className="text-primary unlock-title  pb-4">
            In the age of AI, data is the most valuable asset your organization
            owns.
          </p>
          <p className="text-primary unlock-title  mb-14">
            Without it, AI is just a smarter search engine. With Astra Intel,
            you can harness the power of AI by integrating it with your unique
            data. Our solutions, including custom chatbots, automated workflows,
            and predictive modeling, are designed to deliver instant value—no
            need for advanced coding or technical expertise. Invest in a quality
            build once, then reap the benefits going forward.
          </p>
          {/* <a className="text-black mb-10 text-lg px-10 py-4 font-semibold border-2 border-black cursor-pointer hover:text-blue-500 hover:border-blue-500 transition-all">
            Explore our available roles
          </a> */}
        </div>
        <div className="mx-4 md:mx-0">
  <img style={{ height: "500px", width: "750px", objectFit: "cover" }} src={picture} alt="picture" className="rounded-l-lg" />
</div>

      </div>
    </div>
  );
};

export default BestPeople;
