import React from "react";

const OurMethod = () => {
  return (
    <div className="w-full bg-lightGray">
      <div className="max-w-7xl mx-auto px-10 md:px-16 py-16">
        <div className="md:max-w-2xl">
          <h1 className="text-black text-xl title pb-6">Our method</h1>
          <p className=" h-1 bg-blue-500 w-8 mb-8"></p>
          <p className="text-primary unlock-title  pb-8">
            Public advocacy and marketing are fast-paced and ever-changing. At
            Astra Intel, we pride ourselves on delivering the flexibility and
            speed that everyday firms can't match or understand. Whether you
            need ongoing managed services, team upskilling, or custom-built
            solutions, we tailor our offerings to meet your goals.
          </p>
          <p className="text-primary unlock-title ">
            For political campaigns, we offer cost-saving strategies during
            off-years and rapid deployment when it's time to launch—an approach
            you won't find in the traditional industry. With us, your data and
            AI solutions are always ready to meet your timeline.
          </p>
          {/* <p className="text-primary unlock-title  pb-8">
            Our process deploys cutting-edge techniques and offers a scalable
            approach that targets multi-year transformations.
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default OurMethod;
