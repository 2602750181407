import React from "react";
import Layout from "../../Layout";
import Header from "./Header";
import WeUnlock from "./WeUnlock";
import OurMethod from "./OurMethod";
import Specs from "./Specs";
import BestPeople from "./BestPeople";
import Featured from "./Featured";

const Index = () => {
  return (
    <Layout>
      <Header />
      <WeUnlock />
      <OurMethod />
      <Specs />
      <BestPeople />
      {/* <Featured /> */}
    </Layout>
  );
};

export default Index;
