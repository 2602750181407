import React from 'react'
import Index from '../components/home'
const index = () => {
  return (
    <div>
      <Index />
    </div>
  )
}

export default index
